@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'antd/dist/reset.css';
@import url('https://fonts.googleapis.com/css2?family=Kantumruy+Pro:wght@400&display=swap');

#root {
    height: 100%;
}

.table-border {
    td {
        border: 1px;
        border-color: inherit;
    }
}

@font-face {
    font-family: 'KHMERMEF1';
    font-style: normal;
    font-weight: normal;
    src: local('KHMERMEF1'), url('KHMERMEF1.woff2') format('woff2');
}

* {
    font-family: "Kantumruy Pro" !important;
}

input[type] {
    font-family: "Kantumruy Pro" !important;
}

.ant-btn-primary {
    background-color: #1677ff !important;
}

.inspection-certificate-print-preview {
    width: 874px;
    height: 1240px;
    border: 1px solid #CCC;
    background-repeat: no-repeat;
    background-size: 874px;
}

@media print {

    .inspection-certificate-print-preview {
        width: 874px;
        height: 1240px;

    }

}

.main-navigation {
    padding-top: 4px;

    .ant-menu-item-icon {
        @apply text-xl #{!important};
    }

    .ant-menu-item-selected {
        @apply bg-blue-400 text-slate-100 #{!important};
    }

    .ant-menu-item {
        padding-top: 0.75rem !important;
    }

    li {
        margin: 0;
        padding: 0;
        margin-block: 0px !important;
        @apply flex flex-col items-center text-white h-auto #{!important};



        .ant-menu-submenu-title {
            padding-inline-end: 20px;
            @apply flex flex-col pt-3 items-center text-white h-auto #{!important};
        }


        .ant-menu-title-content {
            margin-inline-start: 0 !important;
        }

        .ant-menu-submenu-arrow {
            display: none;
            // inset-inline-end: 4px;
        }
    }
}
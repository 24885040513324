@media print
{
    @page
    {
        size: 8.5in 11in; 
        border: 1px solid blue; 
        margin: auto;
    }
    .pagebreak { page-break-before: always; }
}

.text-eng {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace !important;
}